import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Columns, Content, Rows } from "../grid"
import Link from "../content/link"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar } from "@fortawesome/free-solid-svg-icons"

function SuccessStoryListing({ item }) {
  const coverImg = getImage(item.cardSettings.cover.localFile)
  const logoImg = getImage(item.coreDetails.logo.localFile)

  return (
    <Link to={`/success-stories/${item.slug}/`}>
      <Content
        gap="md"
        paddingLeft="none"
        paddingRight="none"
        className="relative height--full card--success-listing"
      >
        <div className="blog-post__author__image card--success-listing__icon-outer">
          <div className="card--success-listing__icon-inner">
            <GatsbyImage
              image={logoImg}
              alt={item.coreDetails.logo.alternativeText}
              quality={90}
            />
          </div>
        </div>
        <Rows gap="sm" className="blog__listing--success mx-sm mb-md">
          <Columns count="1">
            <Content paddingRight="none" paddingLeft="none" className="mt-xxl">
              <p className="h4">{item.coreDetails.name}</p>
              <p>{item.cardSettings.text}</p>
            </Content>
          </Columns>
          <Columns count="1">
            <Content paddingRight="none" paddingLeft="none">
              {item.cardSettings.statistic.map(
                ({ coreStatText, postStatText }, index) => (
                  <div className="flex" key={index}>
                    <FontAwesomeIcon
                      style={{ marginTop: 2 }}
                      className={`h4 mr-xxs text--color-${item.coreDetails.diamondColor}`}
                      icon={faStar}
                    />
                    <div>
                      <p className="h4 text--small mb-xxxs">{coreStatText}</p>
                      <p className="text--xs">{postStatText}</p>
                    </div>
                  </div>
                ),
              )}
            </Content>
          </Columns>
        </Rows>
      </Content>
    </Link>
  )
}

export default SuccessStoryListing
