import React from "react"
import { graphql } from "gatsby"
import Section from "../../components/containers/section"
import SectionContent from "../../components/containers/section-content"
import Copy from "../../components/content/copy"
import Heading from "../../components/content/heading"
import { Columns, Content, Rows } from "../../components/grid"
import Main from "../../components/layout/main"
import SectionBackground from "../../components/containers/section-background"
import Pagination from "../../components/interactive/pagination"
import Seo from "../../components/seo/seo"
import SuccessStoryListing from "../../components/interactive/success-story-card"
import { StaticImage } from "gatsby-plugin-image"
import CtaGroup from "@src/components/containers/cta-group"
import Button from "@src/components/interactive/button"

function SuccessStory({ pageContext, data }) {
  const { heading, seo } = data.strapiPageSuccessStories

  const seoData = {
    title: seo.metaTitle,
    description: seo.metaDesc,
    url:
      pageContext.currentPage > 1
        ? `/success-stories/${pageContext.currentPage}/`
        : "/success-stories/",
  }

  return (
    <Main>
      <Seo customData={seoData} />
      <Section theme="dark">
        <SectionBackground>
          <div className="hero-image hero-image--success flex flex--column">
            <div className="width--full">
              <StaticImage
                src="../../images/success-stories/success_stories_hero.jpg"
                layout="fullWidth"
              />
            </div>
            <div className="hero-image--success-bg" />
          </div>
        </SectionBackground>
        <SectionContent hero paddingTop="lg">
          <Rows gap="none">
            <Columns count="1">
              <Content>
                <Heading className="mt-lg" alignment="center" level={1}>
                  {heading.headingText}
                </Heading>
                <Copy className="tab-px-xxl">
                  <p className="text--center">{heading.postHeadingText}</p>
                </Copy>
                <CtaGroup alignment="center">
                  <Button href="https://lp.lunio.ai/demo-v1">
                    Get Started
                  </Button>
                </CtaGroup>
              </Content>
            </Columns>
          </Rows>
        </SectionContent>
        <SectionContent>
          <Rows gap="xl">
            <div className="blog__articles blog__articles--resources blog__articles--success">
              {data.allStrapiSuccessStories.nodes.map((item, index) => (
                <SuccessStoryListing key={index} item={item} />
              ))}
            </div>
            <Pagination
              rootslug="success-stories"
              numPages={pageContext.numStoryPages}
              currentPage={pageContext.currentPage}
            />
          </Rows>
        </SectionContent>
        <SectionContent paddingTop="none" paddingBot="xl">
          <div className="relative border--rounded text-gradient__purple-blue overflow--hidden">
            <Rows>
              <Columns count="2" alignment="center">
                <Content className="py-xl pl-lg pr-lg tab-pr-none">
                  <Heading className="text--color-white" alignment="left">
                    Get the Marketing Edge in your Industry
                  </Heading>
                  <CtaGroup>
                    <Button href="https://lp.lunio.ai/demo-v1">
                      Get Started
                    </Button>
                  </CtaGroup>
                </Content>
                <div className="absolute width--full height--full accent__planet">
                  <StaticImage src="../../images/success-stories/planet-fighting.png" />
                </div>
              </Columns>
            </Rows>
          </div>
        </SectionContent>
      </Section>
    </Main>
  )
}

export const query = graphql`
  query SuccessStoryPageQuery($skip: Int!, $limit: Int!) {
    strapiPageSuccessStories {
      heading {
        headingText
        postHeadingText
      }
      seo {
        metaTitle
        metaDesc
        shareImage {
          localFile {
            publicURL
          }
        }
      }
    }
    allStrapiSuccessStories(
      sort: { fields: coreDetails___priority, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        slug
        cardSettings {
          cover {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          text
          statistic {
            coreStatText
            postStatText
          }
        }
        coreDetails {
          diamondColor
          name
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            alternativeText
          }
        }
      }
    }
  }
`

export default SuccessStory
